html {
  box-sizing: border-box;
  font-size: 16px;
  font-family: raleway;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  line-height: 1.6;
  margin: 0;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

#sidebar {
  background-color: #000;
  width: 350px;
  margin-left: -350px;
  display: flex;
  height: 100vh;
  z-index: 10;
  position: fixed;
  left: 0;
}

#photo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: -275px;
  margin-bottom: 10px;
}

#profile-photo {
  width: 150px;
  height: 150px;
  background-image: url("./images/profile_pic.jpg");
  background-position: top center;
  background-size: cover;
  filter: grayscale(1);
  border-radius: 50%;
}

#menu {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

#menu > nav > a {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 0px 5px;
  text-decoration: none;
  color: #FFF;
  font-family: raleway;
  font-size: 1.25rem;
}

#social-links {
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-end;
  padding: 20px;
}

#hero {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2)), url("./images/home_hero.jpg");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

#hero-content {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  text-align: center;
  color: #FFF;
}

#hero-title {
  font-family: cinzel;
  font-weight: 500;
  line-height: 1;
  font-size: 5rem;
  margin: 16px;
}

#hero-subtitle {
  width: 80%;
  font-family: raleway;
  font-weight: 500;
  font-size: 1.75rem;
  line-height: 1.4;
  margin: 16px;
}

#sticky-header {
  height: 65px;
  position: fixed;
  width: 100%;
  max-width: inherit;
  color: #FFF;
  background-color: #000;
  font-size: 30px;
  z-index: 1;
}

#toggleOpen {
  position: absolute;
  top: 12px;
  left: 20px;
  color: #FFF;
  font-size: 30px;
  z-index: 1;
  cursor: pointer;
}

#toggleClose {
  position: absolute;
  top: 20px;
  left: 20px;
  color: #FFF;
  font-size: 30px;
  cursor: pointer;
  z-index: 11;
}

#footer {
  width: 100%;
  padding: 32px;
  font-size: 1rem;
  color: #FFF;
  background-color: #000;
  text-align: center;
}

@media only screen and (max-width: 1120px) {
  #hero {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    background-attachment: scroll;
  }
  #page-container {
    width: 100vw;
  }
}
@media only screen and (max-width: 480px) {
  #sidebar {
    width: 100vw;
    margin-left: -100vw;
  }
  #hero-title {
    font-size: 4rem;
  }
}
.contentTitle {
  width: 100%;
  padding: 24px;
  text-transform: uppercase;
  font-size: 1.75rem;
  color: #FFF;
  background-color: #000;
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 20px;
}

.contentTitle {
  width: 100%;
  padding: 24px;
  text-transform: uppercase;
  font-size: 1.75rem;
  color: #FFF;
  background-color: #000;
  text-align: center;
}

.itemTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 30%;
}

.itemDescription {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 70%;
  padding-left: 32px;
  border-left: 1px #000 solid;
}

.itemTitle, .itemDescription {
  padding: 24px;
}

.item {
  width: 70%;
  min-height: 150px;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
  }
  .itemTitle {
    width: 50%;
    align-items: center;
    text-align: center;
    font-size: 1.1rem;
    padding: 16px;
    border-bottom: 1px #000 solid;
  }
  .itemDescription {
    width: 100%;
    padding: 16px 0px;
    border-left: none;
    text-align: center;
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    padding: 16px;
    border-radius: 16px;
  }
}
@media only screen and (max-width: 480px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
  }
  .itemTitle {
    width: 75%;
    align-items: center;
    text-align: center;
    font-size: 1.1rem;
    padding: 16px;
    border-bottom: 1px #000 solid;
  }
  .itemDescription {
    width: 100%;
    padding: 16px 0px;
    border-left: none;
    text-align: center;
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 16px;
    border-radius: 16px;
  }
}
